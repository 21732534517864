import type { User } from "@/payload-types";
import { createContext, useContext } from "react";

export const AuthContext = createContext<{
  user: User | null | undefined;
}>({
  user: undefined,
});

export const useUser = () => useContext(AuthContext);
